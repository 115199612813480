import Repository     from './Repository'
import ReportResponse from '@/models/ReportResponse'
import Customer       from '@/models/Customer'
import moment         from 'moment'

/**
 * Este modelo generaliza as classes de repositório
 */
export default class CustomerRepository extends Repository {

    /**
     * Retorna uma request para gerar um relatório de clientes por data de registro, paginado e filtrado
     *
     * @param {QueryPagination} pagination       Instância de configurações de paginação
     * @param {QueryFilter}     additionalParams Instância de configurações de filtro
     *
     * @returns {ReportResponse}
     */
    static async fetchReport(pagination = {}, additionalParams = {}) {
        let params = { }
        if (pagination) {
            params = {
                page    : pagination.page,
                per_page: pagination.perPage || pagination.per_page,
                count : true,
            }
        }

        let response = await Customer.request({
            url   : `/report/customers`,
            method: 'get',
            params: {
                ...params,
                ...additionalParams,
            },
        })

        if (!response)
            return

        if (additionalParams.export) {
            // Faz download do csv
            let csvContent = "data:text/csv;charset=utf-8," + response.data
            let filename = `${moment().format('YYYY-MM-DDTHH:mm:ss')}-export-customer.csv`
            var encodedUri = encodeURI(csvContent)
            var link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", filename)
            document.body.appendChild(link)              // Required for FF
            link.click()                                 // This will download the data file named "my_data.csv".

            return null
        }

        return new ReportResponse(response)
    }

}