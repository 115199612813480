<template>
    <v-container class="pa-0">
        <v-alert type="info" dense class="my-4">{{ $t('views.reports.layout_info') }}</v-alert>
        <ReportHeader
            :title="$t('views.reports.customer.created_at.title')"
            :description="$t('views.reports.customer.created_at.description')"
            icon="fas fa-user"
        >
            <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
                <v-row>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.from.label')"
                            :placeholder="$t('views.reports.commons.form.from.placeholder')"
                            :hint="$t('views.reports.commons.form.from.hint')"
                            v-model="params.from"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                        />
                    </v-col>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.to.label')"
                            :placeholder="$t('views.reports.commons.form.to.placeholder')"
                            :hint="$t('views.reports.commons.form.to.hint')"
                            v-model="params.to"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ReportGroupByCombobox
                            v-model="computedGroupBy"
                            :label="$t('views.reports.commons.form.group_by.label')"
                            :hint="$t('views.reports.commons.form.group_by.hint')"
                            :placeholder="$t('views.reports.commons.form.group_by.placeholder')"
                            :availableItems="groupByAvailableItems"
                            multiple small-chips
                            clearable hide-selected
                            deletable-chips persistent-hint
                            :max="3"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                            :label="$t('views.reports.commons.form.count.label')"
                            v-model="computedCount"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <small>{{ $t('views.reports.customer.sort_hint') }}</small>
                    </v-col>
                </v-row>

                <v-btn
                    color="success"
                    type="submit"
                    :loading="loading"
                    :disabled="!isFormValid"
                >{{ $t('actions.confirm') }}</v-btn>
            </v-form>
        </ReportHeader>

        <ReportContent
            :headers="computedHeaders"
            :reportResponse="reportResponse"
            :loading="loading"
            :pagination.sync="pagination"
            :hasError="hasError"
            :count="computedLastCount"
            @export="exportReport"
        >
            <template #item="{ item }">
                <tr v-if="!computedLastCount">
                    <td>{{ item.name }}</td>
                    <td>{{ item.document }}</td>
                    <td>{{ item.created_at | datetime }}</td>
                    <td>
                        <router-link :class="isLightTheme ? 'show_customer': '' " :to="{ name: 'customers.details', params: { id: item.id } }"
                        >{{ $t('views.reports.customer.created_at.content.go_to_customer') }}</router-link>
                    </td>
                </tr>
            </template>
        </ReportContent>

    </v-container>
</template>
<script>
import HasErrorHandlerMixin  from '@/mixins/HasErrorHandlerMixin'
import RateColor             from '@/shared/mixins/RateColor'
import DateInput             from '@/shared/components/inputs/DateInput'
import ReportHeader          from '../commons/ReportHeader'
import ReportContent         from '../commons/ReportContent'
import CustomerRepository    from '@/repositories/CustomerRepository'
import ReportResponse        from '@/models/ReportResponse'
import GroupByConfig         from '@/models/GroupByConfig'
import ReportGroupByCombobox from '@/components/inputs/ReportGroupByCombobox'

export default {
    mixins    : [ HasErrorHandlerMixin, RateColor ],
    components: { DateInput, ReportHeader, ReportContent, ReportGroupByCombobox },
    data      : vm => ({
        params: {
            from    : vm.$route.query.from     || '',
            to      : vm.$route.query.to       || '',
            group_by: vm.$route.query.group_by || '',
            count   : vm.$route.query.count    || 'true', // Por padrão mostra apenas as contagens
        },
        lastParams: {
            from    : undefined,
            to      : undefined,
            group_by: undefined,
            count   : undefined,
        },

        groupByAvailableItems: [
            new GroupByConfig({ value: 'field:subsidiary_id',       label: vm.$t('views.reports.customer.created_at.form.group_by.company') }),
            // new GroupByConfig({ value: 'time:created_at:1:WEEKDAY', label: vm.$t('views.reports.commons.form.group_by.weekday') }), // [TODO][FID-1022]

            new GroupByConfig({ value: 'time:created_at:1:HOUR',    label: vm.$t('views.reports.commons.form.group_by.hour') }),
            new GroupByConfig({ value: 'time:created_at:1:DAY',     label: vm.$t('views.reports.commons.form.group_by.day') }),
            new GroupByConfig({ value: 'time:created_at:1:WEEK',    label: vm.$t('views.reports.commons.form.group_by.week') }),
            new GroupByConfig({ value: 'time:created_at:1:MONTH',   label: vm.$t('views.reports.commons.form.group_by.month') }),
            new GroupByConfig({ value: 'time:created_at:1:YEAR',    label: vm.$t('views.reports.commons.form.group_by.year') }),
        ],

        loading       : false,
        hasError      : false,
        isFormValid   : true,
        reportResponse: new ReportResponse,
        pagination    : {
            page    : vm.$route.query.page || 1,
            per_page: vm.$constants.getConstant('REPORT_DEFAULT_PER_PAGE'),
        },
    }),
    methods: {
        async submit() {
            this.pagination.page = 1
            await this.fetchData()
        },
        async fetchData() {
            this.loading      = true
            this.hasError     = false
            this.dataResponse = {}

            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading = false
                return
            }

            // Atualiza parâmetros da busca na query para poder voltar com o navegador
            this.$router.push({ query: this.computedQuery })

            // Logo em seguida faz request para listar
            this.reportResponse = await CustomerRepository.fetchReport(this.computedPagination, this.params)
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading    = false
        },
        async exportReport() {
            // [TEMP][FID-463] Implementação provisória, será melhorada na FID-463
            this.loading  = true
            this.hasError = false

            // Valida parâmetros atuais
            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading = false
                return
            }

            // Logo em seguida faz request para exportar
            await CustomerRepository.fetchReport(this.computedPagination, { ...this.params, export: true, count: false })
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading    = false
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
    computed: {
        computedQuery() {
            return {
                ...this.computedPagination,
                ...this.params,
            }
        },
        computedGroupBy: {
            get() {
                return (this.params.group_by || '')
                    .split(';')
                    .map(i => this.groupByAvailableItems.find(a => a.value == i))
                    .filter(i => !!i)
            },
            set(v) {
                this.params.group_by = v.map(i => i.value).join(';')
            },
        },
        computedPagination() {
            if (this.computedCount) {
                return {
                    ...this.$lodash.omit(this.pagination, ['page']),
                    per_page: this.$constants.getConstant('PAGINATION_MAX_PER_PAGE'),
                }
            }

            return this.pagination
        },        
        computedCount: {
            get() {
                return (this.params.count || '').includes('true')
            },
            set(v) {
                this.params.count = v ? 'true' : 'false'
            },
        },
        computedLastCount() {
            return (this.lastParams.count || '').includes('true')
        },
        computedHeaders() {
            return this.computedLastCount ?
            [
                { text: this.$t('views.reports.commons.header.label') },
                { text: this.$t('views.reports.commons.header.count'), align: 'right' },
            ] : [
                { text: this.$t('views.reports.customer.created_at.content.name'),       value: 'name' },
                { text: this.$t('views.reports.customer.created_at.content.document'),   value: 'document' },
                { text: this.$t('views.reports.customer.created_at.content.created_at'), value: 'created_at' },
                { text: this.$t('tables.actions') },
            ]
        },
    },
    watch: {
        pagination: {
            deep: true,
            immediate: true,
            handler(newPagination) {
                this.fetchData()
            },
        },
    },
}
</script>

<style scoped>
.show_customer {
    color: #304FFE !important;
}
</style>